import { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChevronLeftOutlinedIcon, GotoAdminPartnerFaviconIcon } from '@getgo/chameleon-icons/react';
import { Button, GotoLogo, Skeleton, Typography } from '@getgo/chameleon-web-react-wrapper';

import PriceInfo from 'components/price-info';
import SessionExpiredModal from 'components/session-expired-modal';
import SessionTimer from 'components/session-timer';
import { useAppDispatch, useAppSelector } from 'hooks';
import { accountKey } from 'modules/global-wrapper';
import { paymentTypeChosen, paymentTypesIsLoading, postPaymentMethodTypes } from 'modules/payment-methods';
import { isSessionExpired, sesssionCancelRedirectUrl } from 'modules/session-details';
import Track, { PaynowBackToCheckout, PaynowLanding } from 'modules/tracking';
import { ACH, PAYMENT_TYPE_CC, PAYMENT_TYPE_DD } from 'utils/constants';
import st from 'utils/shared-translations';
import ACHPay from './ach-pay';
import CreditCardPay from './credit-card-pay';
import DirectDebitPay from './direct-debit-pay';
import PaymentMethodOptions from './payment-method-options';

import './pay-now.css';

const PayNow: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const selectedAccountKey = useAppSelector(accountKey);

  const selectedSessionIsExpired = useAppSelector(isSessionExpired);
  const selectedCancelRedirectUrl = useAppSelector(sesssionCancelRedirectUrl);

  const selectedPaymentTypeIsLoading = useAppSelector(paymentTypesIsLoading);
  const selectedPaymentType = useAppSelector(paymentTypeChosen);

  useEffect(() => {
    if (selectedAccountKey) {
      Track(PaynowLanding, {});
      dispatch(postPaymentMethodTypes());
    }
  }, [dispatch, selectedAccountKey]);

  const redirectToCheckout = () => {
    Track(PaynowBackToCheckout, {});
    window.location.assign(selectedCancelRedirectUrl);
  };

  return (
    <div className="pay-now">
      {selectedSessionIsExpired && <SessionExpiredModal redirectType="Checkout" redirectMethod={redirectToCheckout} />}
      <nav className="pay-now__nav">
        <Button size="medium" variant="neutral" leadingIcon={<ChevronLeftOutlinedIcon />} onClick={redirectToCheckout}>
          {intl.formatMessage(st['back.to.previous.page'])}
        </Button>
      </nav>
      <header className="pay-now__header">
        <SessionTimer />
        <GotoLogo>
          <GotoAdminPartnerFaviconIcon />
        </GotoLogo>
      </header>
      <aside className="pay-now__aside">
        <PriceInfo />
      </aside>
      <main className="pay-now__main">
        {
          {
            '': (
              <>
                <Typography tag="h1" variant="heading-medium">
                  {intl.formatMessage(st['payment.methods.header'])}
                </Typography>
                {selectedPaymentTypeIsLoading ? (
                  <Skeleton size="xxxlarge" className="pay-now__skeleton"></Skeleton>
                ) : (
                  <PaymentMethodOptions />
                )}
              </>
            ),
            [PAYMENT_TYPE_CC]: <CreditCardPay />,
            [ACH]: <ACHPay />,
            [PAYMENT_TYPE_DD]: <DirectDebitPay />,
          }[selectedPaymentType]
        }
      </main>
      <footer className="pay-now__footer">
        <Typography variant="caption-medium" color="type-color-secondary">
          <FormattedMessage
            {...st['payment.tnc']}
            values={{
              // @ts-ignore
              'tos-link': (msg: string) => (
                <a
                  href="https://www.goto.com/company/legal/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography tag="span" variant="body-small" color="link">
                    {msg}
                  </Typography>
                </a>
              ),
              // @ts-ignore
              'privacy-policy': (msg: string) => (
                <a href="https://www.goto.com/company/legal/privacy" target="_blank" rel="noopener noreferrer">
                  <Typography tag="span" variant="body-small" color="link">
                    {msg}
                  </Typography>
                </a>
              ),
              // @ts-ignore
              'anti-spam': (msg: string) => (
                <a href="https://www.goto.com/company/legal/anti-spam-policy" target="_blank" rel="noopener noreferrer">
                  <Typography tag="span" variant="body-small" color="link">
                    {msg}
                  </Typography>
                </a>
              ),
            }}
          />
        </Typography>
      </footer>
    </div>
  );
};

export default PayNow;
