import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { daoTransactionAchAddPost, daoTransactionAchPayPost } from 'dao/transaction-ach-dao';
import { Action } from 'types/action';
import { Thunk } from 'types/root';
import {
  AddAchPayload,
  AddAchResp,
  TransactionsAchState,
  UseAchPayPayload,
  UseAchPayResp,
} from 'types/transaction-ach';

// Add ACH
export const postTransactionAddAch = createAsyncThunk<TransactionsAchState, AddAchPayload, Thunk>(
  'transactions/addAch',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoTransactionAchAddPost(accountKey, sessionId, payload);
    return response as TransactionsAchState;
  },
);

// Pay using ACH
export const postTransactionUseAchPay = createAsyncThunk<TransactionsAchState, UseAchPayPayload, Thunk>(
  'transactions/payAch',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoTransactionAchPayPost(accountKey, sessionId, payload);
    return response as TransactionsAchState;
  },
);

const initialState: TransactionsAchState = {
  isLoading: false,
  addAchResp: {} as AddAchResp,
  useAchPayResp: {} as UseAchPayResp,
};

const transactionsAchSlice = createSlice({
  name: 'transactions-ach',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      // add ach
      .addCase(postTransactionAddAch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTransactionAddAch.fulfilled, (state, action: Action) => {
        state.isLoading = false;
        state.addAchResp = action.payload;
      })
      .addCase(postTransactionAddAch.rejected, (state) => {
        state.isLoading = false;
      })
      // pay using ach
      .addCase(postTransactionUseAchPay.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTransactionUseAchPay.fulfilled, (state, action: Action) => {
        state.isLoading = false;
        state.useAchPayResp = action.payload;
      })
      .addCase(postTransactionUseAchPay.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default transactionsAchSlice.reducer;
