export const RECEIPIENT = 'GoTo Technologies';
export const CONTACT_PRODUCT_SALES = 'https://www.goto.com/learn-more';

export const supportedLocales = ['de_DE', 'en_US', 'es_ES', 'fr_FR', 'it_IT', 'ja_JP', 'ko_KR', 'pt_BR', 'zh_CN'];

export const PAYMENT_METHODS_TYPES = Object.freeze([
  'CREDIT_CARD',
  'CREDIT_CARD_WITH_SCA',
  'DIRECT_DEBIT_SEPA', // used in all EU countries with EUR
  'DIRECT_DEBIT_BACS', // used in UK with GBP
  'DIRECT_DEBIT_BECS', // used in Australia with AUD
  'DIRECT_DEBIT_PAD', // used in Canada with CAD
  'ACH',
  'RAZORPAY',
  'PAYPAL',
]);

export const PAYMENT_METHODS_CC_TYPES = Object.freeze(['CREDIT_CARD', 'CREDIT_CARD_WITH_SCA']);

export const PAYMENT_METHODS_DD_TYPES = Object.freeze(['DIRECT_DEBIT_SEPA']);

export const PAYMENT_METHODS_ACH_TYPES = Object.freeze(['ACH']);

// Credit Card
export const CREDIT_CARD = 'CREDIT_CARD';
export const CREDIT_CARD_SAVE = 'CreditCardSave';
export const CREDIT_CARD_ADD_PAY = 'CreditCardAddPay';
export const CREDIT_CARD_NUMBER = 'number';
export const CREDIT_CARD_CVV = 'securityCode';
export const CREDIT_CARD_EXP = 'month-year';
export const CREDIT_CARD_DEFAULT = 'creditCardDefault';
export const PAYMENT_TYPE_CC = 'cc';

// Direct Debit
export const DIRECT_DEBIT = 'DIRECT_DEBIT';
export const DIRECT_DEBIT_FORM = 'DIRECT_DEBIT_FORM';
export const DIRECT_DEBIT_INFO = 'DIRECT_DEBIT_INFO';
export const DD_SEPA_DETAILS = Object.freeze({
  crditorName: 'LogMeIn Inc.',
  creditorIdentifier: 'GB53ZZZSDDBARC000007495896029',
  paymentType: 'Recurring payment',
  mandateReference: 'LOGMEINIE-ZE23JMSG',
  streetAddress: 'The Reflector, 10 Hanover Quay',
  city: 'Dublin 2',
  postalCode: 'D02R573',
  Country: 'Republic of Ireland',
});
export const PAYMENT_TYPE_DD = 'DD';

// ACH
export const ACH = 'ACH';
export const ACH_FORM = 'ACH_FORM';
export const ACH_INFO = 'ACH_INFO';
export const achAccountTypeList = ['Corporate', 'Checking', 'Savings'];

// Classic pass product families
export const CENTRAL_PRODUCT_FAMILY = 'Central';
export const PRO_PRODUCT_FAMILY = 'Pro';
export const HAMACHI_PRODUCT_FAMILY = 'Hamachi';

export const classicProductFamilyKey: ReadonlyArray<string> = [
  CENTRAL_PRODUCT_FAMILY,
  PRO_PRODUCT_FAMILY,
  HAMACHI_PRODUCT_FAMILY,
];

export const PURCHASE_FLOW_SUBSCRIPTION = 'subscription';
export const PURCHASE_FLOW_QUOTE = 'quote';
export const PURCHASE_FLOW_RENEWALS = 'renewals';
